import React from 'react'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import Layout from '../components/layout'
import Header from '../components/Header'
import BreadCrumb from '../components/Share/BreadCrumb'
const breadcrumb = [
  {
    link: '/',
    title: 'Home',
  },
  {
    title: 'Impressum',
  },
]

const Imprint = () => {
  return (
    <Layout>
      <Menu />
      <Header page="imprint" />
      <div className="big-background-image pb-8  bg-lendis-black bg-no-repeat bg-top lg:pb-40 pt-40 px-3">
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">Impressum</h1>
        </div>
        <BreadCrumb breadCrumbItems={breadcrumb} textPage />
        <div className="bg-white mx-auto container">
          <div className="py-12">
            <div className="mx-8 mt-8 lg:ml-16 lg:mr-16">
              <h4 className="uppercase font-semibold mt-4">Angaben gemäß § 5 TMG</h4>
              <p> Lendis GmbH</p>
              <p>Oranienburger Straße 66, 10117 Berlin</p>
              <p>Geschäftsführer: Julius Bolz, Stavros Papadopoulos</p>
              <p>Handelsregister: Amtsgericht Charlottenburg, HRB 199057B</p>
              <p>E-Mail: info@lendis.io</p>
              <p>Tel: +49 (0) 30 311 99 676</p>
              <p>Wir sind Montag – Freitag von 08.00 Uhr bis 20.00 Uhr für Sie erreichbar.</p>

              <h4 className="uppercase font-semibold mt-4">Streitschlichtung</h4>
              <p>
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
                bereit. https://ec.europa.eu/consumers/odr
              </p>

              <p>
                Unsere E-Mail-Adresse finden Sie oben im Impressum. Wir sind nicht bereit oder
                verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
                teilzunehmen.
              </p>
              <h4 className="uppercase font-semibold mt-4">Haftung für Inhalte</h4>
              <p>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
                als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
                rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
                konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
              </p>
              <h4 className="uppercase font-semibold mt-4">Haftung für Links</h4>
              <p>
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir
                keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
                Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
                Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Links umgehend entfernen.
              </p>
              <h4 className="uppercase font-semibold mt-4">Urheberrecht</h4>
              <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
                Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
                bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
                Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                umgehend entfernen.
              </p>
              <h4 className="uppercase font-semibold mt-4">Bildrechte</h4>
              <p>Icons – ©Freepik – www.flaticon.com</p>
              <p>Vitra Eames Chair - © Vitra International AG</p>
              <p>Bilder im Katalog „Pflanzen“ - © Verdissimo Forever Young S.A.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Imprint
